@import url("https://fonts.googleapis.com/css?family=Poppins");
@import url("https://fonts.googleapis.com/css?family=Urbanist");

:root {
  --gray-sec-500: rgba(34, 39, 47, 1);
  --primary-500: #9008d7;
  --primary-400: #cd6eff;
}
.ant-input::placeholder, .ant-input-number-input::placeholder {
  opacity: 0.5 !important;
}

@font-face {
  font-family: "Antipasto Pro";
  src: url('fonts/AntipastoPro-Light.eot');
  src: url('fonts/AntipastoPro-Light.eot') format('embedded-opentype'),
  url('fonts/AntipastoPro-Light.woff2') format('woff2'),
  url('fonts/AntipastoPro-Light.woff') format('woff'),
  url('fonts/AntipastoPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Antipasto Pro";
  src: url('fonts/AntipastoPro-Regular.eot');
  src: url('fonts/AntipastoPro-Regular.eot') format('embedded-opentype'),
  url('fonts/AntipastoPro-Regular.woff2') format('woff2'),
  url('fonts/AntipastoPro-Regular.woff') format('woff'),
  url('fonts/AntipastoPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Antipasto Pro";
  src: url('fonts/AntipastoPro-Medium.eot');
  src: url('fonts/AntipastoPro-Medium.eot') format('embedded-opentype'),
  url('fonts/AntipastoPro-Medium.woff2') format('woff2'),
  url('fonts/AntipastoPro-Medium.woff') format('woff'),
  url('fonts/AntipastoPro-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Antipasto Pro Bold */
@font-face {
  font-family: "Antipasto Pro";
  src: url('fonts/AntipastoPro-Bold.eot');
  src: url('fonts/AntipastoPro-Bold.eot') format('embedded-opentype'),
  url('fonts/AntipastoPro-Bold.woff2') format('woff2'),
  url('fonts/AntipastoPro-Bold.woff') format('woff'),
  url('fonts/AntipastoPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Antipasto Pro";
  src: url('fonts/AntipastoPro-ExtraBold.eot');
  src: url('fonts/AntipastoPro-ExtraBold.eot') format('embedded-opentype'),
  url('fonts/AntipastoPro-ExtraBold.woff2') format('woff2'),
  url('fonts/AntipastoPro-ExtraBold.woff') format('woff'),
  url('fonts/AntipastoPro-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: 'Mazzard';
  src: url('fonts/mazzard/MazzardH-Light.ttf');
  font-weight: 0;
  font-style: normal;
}

@font-face {
  font-family: 'Mazzard Light';
  src: url('fonts/mazzard/MazzardH-Light.ttf');
  font-weight: 0;
  font-style: normal;
}


body {
  margin: 0;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Jost', 'sans-serif', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Antipasto Pro', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Lato', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

a {
  text-decoration-line: none;
}
input, .ant-select-selector {
  height: 48px !important;
}
.ant-select-selection-placeholder, .ant-select-selection-item{
  display: inline-flex;
    height: auto;
    align-items: center;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-btn-default {
  padding-bottom: 8px !important;
}

.ant-form-item-label label {
  width: 100% !important;
}
.ant-form-item-label {
  padding: 2px !important;
}

.hint {
  
  width: 100%;
  text-align: left;
}
.hint span {
  color: rgb(129, 136, 163) !important;
}

html[data-theme=dark] a {
  color: #75ba8e !important;
}

html[data-theme=dark] a:hover {
  color: #83d09f !important;
}

.ant-tabs-mobile {
  width: 80vw !important;
  max-width: 1000px !important;
}
.ant-tabs-content-holder, .ant-tabs-tabpane {
  width: 75vw !important;
  max-width: 900px !important;
} */

.community-bg {
  background-image: url("assets/images/community.svg");
}
.ant-tabs-nav .ant-tabs-tab {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  background: var(--gray-sec-500) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.ant-collapse-borderless >.ant-collapse-item:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05) !important;
}
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
  border-radius: 0 !important;
}
th::before {
  width: 0 !important;
}

.number-font{
  font-family: 'Mazzard', Courier, monospace !important;
  font-weight: 100;
}

.number-font-light{
  font-family: 'Mazzard Light', Courier, monospace !important;
  font-weight: 100;
}
.ant-typography-copy {
  color: #9008D7!important;
}

textarea{
  color: #ffffff!important;
}
.ant-btn-background-ghost, .ant-btn-ghost{
  color: #ffffff!important;
  font-weight: bold;
  border-color: #9008D7!important;
}

.ant-notification-notice{
  /* background-color: #9008D7!important; */
  
}
.ant-notification-notice-description, .ant-notification-notice-message, .ant-notification-close-icon{
  /* color: #ffffff!important; */
}

.ant-checkbox-inner {
  border-color: #ffffff2d !important;
  background-color: #15181d;
}

.ant-typography-disabled{
  user-select: auto!important;
  cursor: auto!important;
}

.ant-table-tbody > tr.ant-table-row:hover > td{
  background-color: #cd6eff!important
}

/* @tailwind base;
@tailwind components; */
@tailwind utilities;

